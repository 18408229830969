.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.withpre {
  background-color: lightgreen;
  white-space: pre;
}

button {
  -webkit-appearance: none;
  opacity: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.votearrow {
    transform: scale(1.3,1.3);
    margin-left: 5px;
    margin-right: 0px;
    cursor: pointer;
}

@media ((min-width: 300px) and (max-width: 750px)) {
  .votearrow {
    transform: scale(1.3,1.3);
    margin-right: 6px;
}
}

.votearrow {
  width: 10px;
  height: 10px;
  border: 0px;
  margin: 3px 2px 6px;
  background: url(https://news.ycombinator.com/grayarrow.gif) no-repeat;
}

a.essay:link {
  color: #000099;
  text-decoration: underline;
  text-decoration-color: #000099;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

a.essay:visited {
  color: #464646;
  text-decoration: underline;
  text-decoration-color: #464646;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


a.essay:active {
  color: red;
  background-color: transparent;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}

.loading-overlay__content {
  background-color: white;
  opacity: 1;
  z-index: 999;
  padding: 20px 30px 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  /* filter: blur(5px); */
}

.loading-overlay__text {
  font-size: 18px;
  font-family: verdana;
  text-align: center;
  color: "black";
  width: "400px";
  height: "150px";
}

.loading-overlay--active {
  opacity: 1;
  pointer-events: all;
}

.app-container{
  /* filter: blur(5px); */
}